import { Box, Button, CardMedia, Container, Grid, Typography, Stack } from '@mui/material'
import React from 'react'
import tick from "../../assets/images/driving-lesson/tick.png"

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import prgrm1 from "../../assets/images/home/prgrm1.png";
import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";

import { useEffect, useState } from "react";

import GreenBanner from '../BaseUrl/GreenBanner';

import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import Register from '../Register/Register';
import { useNavigate } from 'react-router-dom';
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics';
import NewUserRegister from '../Register/NewUserRegister';
import { toast } from 'sonner';

const styles = {
    greenBox: {
        background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '40px 80px', marginTop: '40px',
        '@media (max-width: 900px)': {
            padding: '30px',
            marginTop: '20px'
        },
    }
}


const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$30",
        prmImage: prgrm1,
        prmTittle: "Behind the Wheel",
        prmDescription:
            "This program covers all essential aspects of safe driving, equipping students with the knowledge and skills needed to become responsible drivers.",
    },
    {
        prmTop1: "Starting",
        prmTop2: "$30",
        prmImage: prgrm1,
        prmTittle: "Behind the Wheel",
        prmDescription:
            "This program covers all essential aspects of safe driving, equipping students with the knowledge and skills needed to become responsible drivers.",
    },
];





const RtStartTest = () => {



    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[4]

                let minObject = course5.courseDetails[0];
                let maxObject = course5.courseDetails[0];

                course5.courseDetails.forEach(item => {
                    if (item.packageamount < minObject.packageamount) {
                        minObject = item;
                    }
                    if (item.packageamount > maxObject.packageamount) {
                        maxObject = item;
                    }
                });

                course5.courseDetails = [maxObject]
                setRoadTesting(course5)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [])
    console.log('roadTesting', roadTesting)



    const [registerStatus, setRegisterStatus] = useState(false)

    const [finalData, setFinalData] = useState({})

    const handlePackageNavigate = () => {
        navigate('/schedule-driving-lessons-2', {
            state: { number: 2 }
        })
    }

    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }



    const studentDetails = JSON.parse(localStorage.getItem('Student'))
    const userid = studentDetails?.customerid

    const [newUser, setNewUser] = useState(false)

    const isStudentExist = JSON.parse(localStorage.getItem('Student'))


    const fetchcheckPackageisExist = async (packageid) => {

        const isLoggedIn = localStorage.getItem('Student')
        try {

            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid,
                studentid: isStudentExist?.customerid || 0
            })
            if (response.data.status) {
                // console.log('response', response?.data?.response[0])


                const dataForGTM = response?.data?.response

                const courseData = dataForGTM?.packages?.map((val) => {
                    return {
                        productName: val?.packagename,
                        productPrice: val?.packageamount
                    }
                })


                googleAnalyticsFunction(dataForGTM, courseData)

                setFinalData(response?.data?.response)
                // setRegisterStatus(true)

                // if (isLoggedIn == null) {
                setNewUser(true)
                // }
                // else {
                //     setRegisterStatus(true)
                // }

            } else {
                // failure(response.data?.message)
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }

    const navigate = useNavigate()


    console.log('finalData', finalData)






    return (

        <>


            <Box sx={{ pt: "50px", pb: "0px", }}>
                <Container maxWidth='lg'>

                    <Grid container spacing={2}>

                        <Grid item xs={12} md={8}>
                            <Typography variant="h4" sx={{ pt: 2, pb: 2, }}>1 ACT Driving Schools Onsite Road Testing Qualifications & Requirements</Typography>
                            <Typography>Any student can take their Road Test at 1ACT Driving Schools. However, depending on Age, the student must meet the following Georgia qualifications.</Typography>
                        </Grid>

                    </Grid>

                    <Grid container spacing={6} mt={4}>

                        <Grid item md={7} sx={{ order: { xs: 1, md: 1, lg: 1 } }} >
                            <Grid sx={{ background: '#F4FAFF', padding: '30px', '@media (max-width: 900px)': { padding: '20px' } }}>
                                <Grid><Typography variant='h2' sx={{ color: 'var(--primary) !important', display: 'inline' }}>Students Under 18&nbsp; <Typography component='h6' sx={{ color: 'var(--primary) !important', fontWeight: '400 !important', display: 'inline' }}></Typography></Typography></Grid>
                                {/* <Typography mb="10px" mt="10px" variant='h6'>For both 16 & 17 Years</Typography>
                                <Typography component='f15w500'> Teens must have permit for one year and one day. </Typography> */}


                                <Grid sx={{ display: 'flex', gap: '20px', flexDirection: 'column', mt: '20px', }}>
                                    <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', '@media (max-width: 900px)': { padding: '20px' } }}>
                                        <Typography my="10px" variant='h6'>16 Year Olds </Typography>
                                        {/* <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Must have a valid Georgia or out-of-state driver's permit for at least 1 year and 1 day.</Typography> */}
                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp;MUST have a valid Georgia Driver's permit OR, out-of-state permit for AT LEAST 1 year and 1 day.</Typography>

                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Must have completed the GA DDS-approved 30-HR Driver’s Education Course with us, another certified school, or online self-paced.</Typography>


                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp;  Must have completed a minimum of 6-HR Behind the Wheel driving lessons with 1 ACT ONLY. (cannot accept lessons from another school).</Typography>
                                    </Grid>
                                    <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', '@media (max-width: 900px)': { padding: '20px' } }}>
                                        <Typography my="10px" variant='h6'>17 Year Olds</Typography>
                                        {/* <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Possess a valid Georgia or out-of-state driver's permit for at least 1 year and 1 day.</Typography>
                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Complete the GA DDS approved 30-HR Driver’s Education Course with us, another certified school, or online self-paced.</Typography>
                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Finish at least 6-HR Behind the Wheel driving lessons with 1 ACT or another certified school.</Typography> */}
                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; MUST have a valid Georgia Driver's permit OR, out-of-state permit for AT LEAST 1 year and 1 day.</Typography>


                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Must have completed the GA DDS approved 30-HR Driver’s Education Course with us, another certified school, or online self-paced.</Typography>




                                        <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ fontSize: '10px', }} />&nbsp; Must have completed a minimum of 6-HR Behind the Wheel driving lessons with us OR another certified school (must provide 6-hr certificate).</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>





                        <Grid item md={4} sx={{ order: { xs: 3, md: 2, lg: 2 }, margin: 'auto' }}>

                            {roadTesting?.courseDetails?.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginTop: '20px', marginBottom: '20px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        {/* <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                Starting
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                ${program.combopackageamount1}
                                            </Typography>
                                        </Grid> */}

                                        <Grid className="prgrm_img" style={{ padding: "10px" }} >
                                            <CardMedia
                                                className=""
                                                component="img"
                                                height="140px"
                                                width="100%"
                                                alt="program Image"
                                                src={roadTesting.productimage}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                        </Grid>

                                        {/* <Grid className="prgrm_con"> */}

                                        <Grid className="prgrm_txt">
                                            <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                    }}
                                                >
                                                    {program.packagename}
                                                </Typography>

                                                {/* <Typography
                                                    variant="h3"
                                                    sx={{ color: "#fff !important" }}
                                                >
                                                    ${program.combopackageamount1}
                                                </Typography> */}

                                                <Stack spacing={1} sx={{ margin: '5px', border: '1px solid #d4d4d8', borderRadius: '5px', padding: '15px' }}>

                                                    <Typography variant='caption'>(Not Completed Classes with 1 ACT)</Typography>
                                                    <Typography variant='caption'>Seeking road test only</Typography>

                                                    <Typography variant='h4' sx={{ fontWeight: 500 }}>${program?.packageamount}</Typography>

                                                    <Button onClick={() => fetchcheckPackageisExist([program.packageid])} sx={{ background: '#0063d3' }} variant='contained'>BUY NOW</Button>

                                                </Stack>


                                                {/* <Typography sx={{ fontSize: "12px !important" }}>
                                                    {roadTesting.description.replace('$125.', '')}
                                                    <b style={{ fontSize: '15px', textDecoration: 'underline' }}>$125</b>
                                                </Typography> */}



                                                {/* <Typography sx={{ fontSize: "12px !important" }}>


                                                    <p>
                                                        Drive Tests are 45-minute sessions where students are assessed for their driving skills. Schedule your road test at your convenience, with flexible Time slots.
                                                    </p>


                                                </Typography> */}


                                                {/* <Typography onClick={handlePackageNavigate} sx={{ marginTop: '20px', fontSize: '18px !important', cursor: 'pointer', color: '#1976d2 !important', textDecoration: 'underline' }}>
                                                    Buy Road Testing at{' '}
                                                    <b style={{ fontSize: '24px', textDecoration: 'underline', margin: '0 5px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', fontWeight: 'bolder' }}>$125</b>
                                                    with Driving Lessons
                                                </Typography> */}

                                                {/* <Typography
                                                    onClick={handlePackageNavigate}
                                                    sx={{
                                                        marginTop: '10px',
                                                        fontSize: '15px !important',
                                                        cursor: 'pointer',
                                                        color: '#ffffff !important',
                                                        textDecoration: 'none',
                                                        background: 'linear-gradient(145deg, #1976d2, #1565c0)',
                                                        padding: '12px',
                                                        borderRadius: '8px',
                                                        display: 'inline-block',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                                                        transition: 'transform 0.2s ease, box-shadow 0.3s ease',
                                                        '&:hover': {
                                                            transform: 'scale(1.03)',
                                                            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                                                        },
                                                        '&:active': {
                                                            transform: 'scale(0.98)',
                                                        },
                                                    }}
                                                >
                                                    Buy Road Test at{' '}
                                                    <b style={{ fontSize: '21px', textDecoration: 'underline' }}>$125</b>{' '}
                                                    With Driving Lessons
                                                </Typography> */}

                                            </Grid>

                                            {/* <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                <a className="btns btn_1 hvrtemp-bouncetemp-totemp-righttemp">
                                                    Buy Road Testing Only
                                                </a>
                                            </Grid> */}


                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </Grid>
                            ))}

                            <Stack direction={'column'} spacing={1} onClick={handlePackageNavigate} sx={{ cursor: 'pointer', width: '330px' }}>

                                <Stack direction={'row'} >

                                    <Stack sx={{ width: '70%', background: '#0063d3', borderRadius: '10px 0 0 10px !important', padding: '10px 0 10px 10px' }} direction={'column'}>

                                        {
                                            roadTesting?.courseDetails?.length > 0 && <Stack direction={'row'} spacing={2} alignItems={'baseline'}>

                                                <Typography variant='body1' sx={{ fontSize: '20px !important', textDecoration: 'line-through', color: 'white !important' }}>${roadTesting?.courseDetails[0]?.packageamount}</Typography>
                                                <Typography variant='h4' sx={{ color: 'white !important' }}>${roadTesting?.courseDetails[0]?.combopackageamount1}</Typography>

                                            </Stack>
                                        }


                                        <Typography variant='caption' sx={{ color: 'white !important' }}>With Driving Lessons (Combo)</Typography>

                                    </Stack>
                                    <Typography variant='h4' sx={{ width: '30%', background: 'black', color: 'white !important', textAlign: 'center', borderRadius: '0 10px 10px 0 !important' }}>BUY NOW</Typography>

                                </Stack>

                                <Stack direction={'row'} >

                                    <Stack sx={{ width: '70%', background: '#0063d3', borderRadius: '10px 0 0 10px !important', padding: '10px 0 10px 10px' }} direction={'column'}>

                                        {
                                            roadTesting?.courseDetails?.length > 0 && <Stack direction={'row'} spacing={2} alignItems={'baseline'}>

                                                <Typography variant='body1' sx={{ fontSize: '20px !important', textDecoration: 'line-through', color: 'white !important' }}>${roadTesting?.courseDetails[0]?.packageamount}</Typography>
                                                <Typography variant='h4' sx={{ color: 'white !important' }}>${roadTesting?.courseDetails[0]?.combopackageamount1}</Typography>

                                            </Stack>
                                        }


                                        <Typography variant='caption' sx={{ color: 'white !important' }}>Completed classes in 1 ACT</Typography>

                                    </Stack>
                                    <Typography variant='h4' sx={{ width: '30%', background: 'black', color: 'white !important', textAlign: 'center', borderRadius: '0 10px 10px 0 !important' }}>BUY NOW</Typography>

                                </Stack>

                            </Stack>

                        </Grid>

                        <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 3, lg: 3 } }}>
                            <Grid sx={{ background: '#F4FAFF', padding: '30px', }}>
                                <Typography variant='h2' sx={{ color: 'var(--primary) !important', display: 'inline' }}> Students 18 and Over </Typography>

                                <Grid sx={{ margin: '30px 0px 0px', }}>
                                    <Grid sx={{ display: 'flex', marginBottom: '20px', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography>We're Here When You're Ready.</Typography></Grid>
                                    <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography>Just make sure you possess a valid Georgia or out-of-state driver's permit. </Typography></Grid>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item xs={12} md={5} sx={{ order: { xs: 4, md: 4, lg: 4 } }}>
                            <Typography
                                component="span1"
                                sx={{
                                    color: 'var(--primary) !important',
                                    fontWeight: '600 !important',
                                    mb: "24px",
                                }}
                            >
                                Test Locations
                            </Typography>

                            <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                                <Typography
                                    sx={{
                                        fontWeight: '400 !important',
                                        border: '1px solid #B5B5B5',
                                        p: 2,
                                        flex: 1
                                    }}
                                >
                                    <b>Alpharetta</b><br />1740 Grassland pkwy, Suite 204, Alpharetta, GA 30004.
                                </Typography>

                                <Typography
                                    sx={{
                                        fontWeight: '400 !important',
                                        border: '1px solid #B5B5B5',
                                        p: 2,
                                        flex: 1
                                    }}
                                >
                                    <b>Lawrenceville</b><br />377 W Pike St, Lawrenceville, GA 30046.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sx={{ order: { xs: 5, md: 5, lg: 5 } }}>
                            <div style={{ paddingTop: '5px', marginTop: '5px !important' }}>
                                <Typography component="span1" sx={{ mb: '10px', fontWeight: '600 !important' }}>Note</Typography>
                                <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}> <FiberManualRecordIcon sx={{ color: 'var(--primary)', fontSize: '12px', }} />&nbsp; Students must arrive to the Testing Location</Typography>
                                <Typography sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline', lineHeight: '1.5 !important' }}> <FiberManualRecordIcon sx={{ color: 'var(--primary)', fontSize: '12px', }} />&nbsp; Students will take their Test in our 1 ACT Driving Schools car.</Typography>
                            </div>
                        </Grid>

                        {/* <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            finalData={finalData}
                            btw={true}
                        /> */}



                        <NewUserRegister
                            openStatus={newUser}
                            setRegisterStatus={setNewUser}
                            finalData={finalData}
                        />

                    </Grid>

                    {/* <Grid sx={styles.greenBox}>
                        <Typography variant='h4' sx={{ fontWeight: '200 !important', color: '#fff !important', mb: '10px', textTransform: 'uppercase', }}>Thousands of New Drivers Trust Us!</Typography>
                        <Typography variant='h4' component='subtittle2' sx={{ color: 'var(--lightyellow) !important', }}>Join the community with our driving lesson combo deals.</Typography>
import { discount } from 'assets/images/e-commerce/discount.png';

                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0px 0px ', width: 'unset' }}>
                            <Grid>
                                <Button variant="outlined" className=''
                                    sx={{
                                        background: "var(--yellow)",
                                        border: "1px solid var(--yellow)",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        padding: '4px 20px',
                                        transition: '1s',
                                        color: '#000',
                                        borderRadius: '0px',

                                        "&:hover": {
                                            background: "#fff",
                                            color: "#fff",
                                            border: "1px solid var(--green)",
                                            boxShadow: "inset 300px 0px 0px var(--green)",
                                            transition: '1s'
                                        },
                                    }}
                                >
                                    With GREAT OFFERS

                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="outlined" className='greenButAni' onClick={() => navigate('/schedule-driving-lessons-2/2')}>
                                    GET NOW
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}

                </Container>

            </Box>

        </>
    )
}

export default RtStartTest
