import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Typography } from '@mui/material';
import HomeAbout from './HomeAbout';
import HomeBanner from './HomeBanner';
import HomeProgram from './HomeProgram';
import HomeFaq from './HomeFaq';
import HomeWhyChoose from './HomeWhyChoose';
import HomeJourney from './HomeJourney';
import HomeGoogleRev from './HomeGoogleRev';
import HomeBotCarosuel from './HomeBotCarosuel';
import Footer from '../../components/Footer';
import HomeDialog from './HomeDialog';

import { useLocation, useParams } from 'react-router-dom'
import ExistingLogin from '../../components/Header/ExistingLogin';

const Home = () => {

  const location = useLocation()
  const { number } = location.state || {}


  console.log('number>>>', number)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])






  const [open, setOpen] = useState(true);


  const handleClose = () => {
    setOpen(false);
  };



  const [openStatus, setRegisterStatus] = useState(false)


  useEffect(() => {
    if (number == 2) {
      setRegisterStatus(true)
    }
  }, [])

  return (
    <>

      <Header />
      {/* {
        !number &&
        <HomeDialog
          open={open}
          handleClose={handleClose}
        />
      } */}


      <ExistingLogin
        openStatus={openStatus}
        setRegisterStatus={setRegisterStatus}
      />

      <HomeBanner />
      <HomeAbout />
      <HomeProgram />
      <HomeGoogleRev />
      <HomeFaq />
      <HomeWhyChoose />
      <HomeJourney />
      <HomeBotCarosuel />
      <Footer />

    </>
  )
}

export default Home;
