const sendEventToGTM = (eventData) => {
    if (window.dataLayer) {
        window.dataLayer.push(eventData);
    }
};

export const googleAnalyticsFunction = (data, courseData) => {
    sendEventToGTM({
        event: 'Initiate Checkout',
        eventCategory: 'Course Purchase Triggered',
        eventAction: 'Popup Opened',
        eventLabel: 'Initiate Checkout',
        course: courseData,
        totalprice: data?.finalcost == 0 ? courseData[0].productPrice : data?.finalcost
    });
};

export const googleTagForGetInTouch = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Enquiry',
                eventCategory: 'Contact Us Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Contact Us',
                data: data || {}
            });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForGetInTouch - `, error)
    }
}

export const googleTagForCompleteRegistration = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Checkout details entry',
                eventCategory: 'Next Button Triggered',
                eventAction: 'Next Button Clicked',
                eventLabel: 'Registration Form - New User',
                data: data || {}
            });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForCompleteRegistration - `, error)
    }
}

export const googleTagForNewUserCardDetails = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Purchase',
                eventCategory: 'Submit Button Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Purchase - New User',
                data: data || {}
            });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForNewUserCardDetails - `, error)
    }
}

export const googleTagForExistingUserCardDetails = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Purchase',
                eventCategory: 'Submit Button Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Purchase - Existing User',
                data: data || {}
            });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForExistingUserCardDetails - `, error)
    }
}