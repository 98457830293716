import {
  Box,
  CardMedia,
  Container,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import prgrm1 from "../../assets/images/home/prgrm1.png";
import prgrm2 from "../../assets/images/home/prgrm2.png";
import prgrm3 from "../../assets/images/home/prgrm3.png";
import prgrm4 from "../../assets/images/home/prgrm4.png";
import prgrm5 from "../../assets/images/home/prgrm5.png";
import axios from "axios";

import Register from "../Register/Register";
import { useNavigate } from "react-router-dom";
import { base_Url } from "../BaseUrl/ApiUrl";


const programsData = [
  {
    prmTop2: "$30",
    prmImage: prgrm1,
    prmTittle: "Behind the Wheel",
    prmDescription:
      "This program covers all essential aspects of safe driving, equipping students with the knowledge and skills needed to become responsible drivers.",
  },
  {
    prmTop2: "$30",
    prmImage: prgrm2,
    prmTittle: "Defensive Driving",
    prmDescription:
      "Learn techniques to anticipate and respond to potential hazards, reducing accident risks and ensuring safer driving practices.",
  },
];

const HomeProgram = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [roadTesting, setRoadTesting] = useState([]);
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: "",
      });
      if (response.data.message === "success") {
        console.log("Home page carousel api data -  ", response?.data?.response);

        const demo = response?.data?.response
          // ?.filter((item) => item.type !== 1)
          .flatMap((item) => item.courseDetails);

        console.log("mycourse", demo);
        // const course5 = response?.data?.response[4]
        setRoadTesting(response?.data?.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  console.log("roadTesting>>", roadTesting);

  const [registerStatus, setRegisterStatus] = useState(false);

  const navigate = useNavigate();


  const handlePackageNavigate = () => {
    navigate('/schedule-driving-lessons-2', {
      state: { number: 2 }
    })
  }


  const buttonTextHelper = (id) => {
    if (id == 1) {
      return `BUY NOW`
    }
    else if (id == 2) {
      return `BUY NOW`
    }
    else if (id == 3) {
      return `BUY NOW`
    }
    else if (id == 4) {
      return `BUY DRIVERS EDUCATION ONLY`
    }
    else if (id == 5) {
      return `BUY ROAD TESTING ONLY`
    }
  }


  return (
    <>
      <Box
        sx={{ background: "var(--lightblue)", padding: "50px 0px" }}
        id="progam_offers"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={9} xs={12}>
              <Typography>Drive Confidently with</Typography>
              <Typography variant="h2" sx={{ pt: 2, fontSize: '32px !important' }}>
                Our Reliable Program Offerings
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <div
            className="slider-container program_slider"
            style={{ marginTop: "50px" }}
          >

            <Slider {...settings}>
              {roadTesting?.map((program, index) => (
                <Grid
                  className="program_box"
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{}}
                >
                  <Grid className="program_box_main" sx={{}}>
                    <Grid className="prgrm_box_line2"></Grid>

                    <Grid className="prgrm_top_con">
                      {
                        (program?.productid != 2 && program?.productid != 3) && <Typography sx={{ color: "#fff !important" }}>
                          Starting
                        </Typography>
                      }

                      <Typography
                        variant="h3"
                        sx={{ color: "#fff !important" }}
                      >
                        ${program?.productid === 5 || program?.productid === 4 ? program?.courseDetails[0]?.combopackageamount2 : program?.baseprice}
                      </Typography>
                    </Grid>

                    <Grid className="prgrm_con">
                      <Grid className="prgrm_img">
                        <CardMedia
                          className=""
                          component="img"
                          height="100%"
                          width="100%"
                          alt="program Image"
                          src={program?.productimage}
                          sx={{ objectFit: "fill" }}
                        />
                      </Grid>
                      <Grid className="prgrm_txt">
                        <Grid className="" style={{ padding: "20px" }}>
                          <Typography
                            variant="h5"
                            sx={{
                              color: "black !important",
                              // fontSize: "18px !important",
                              // margin: "0px 0px 16px",
                              textAlign: "center",
                            }}
                          >
                            {program?.productname}
                          </Typography>

                          {/* description text */}
                          {
                            program.type == 5 ?

                              <>
                                <Typography sx={{ fontSize: "12px !important" }}>


                                  <p >
                                    Drive Tests are 45-minute sessions where students are assessed for their driving skills. Schedule your road test at your convenience, with flexible Time slots.
                                  </p>


                                </Typography>

                                <Typography
                                  onClick={handlePackageNavigate}
                                  sx={{
                                    marginTop: '10px',
                                    fontSize: '15px !important',
                                    cursor: 'pointer',
                                    color: '#ffffff !important',
                                    textDecoration: 'none',
                                    background: 'linear-gradient(145deg, #1976d2, #1565c0)',
                                    padding: '12px',
                                    borderRadius: '8px',
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                                    transition: 'transform 0.2s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                      transform: 'scale(1.03)',
                                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                                    },
                                    '&:active': {
                                      transform: 'scale(0.98)',
                                    },
                                  }}
                                >
                                  Buy Road Test at{' '}
                                  <b style={{ fontSize: '21px', textDecoration: 'underline' }}>$125</b>{' '}
                                  With Driving Lessons
                                </Typography>


                              </>

                              : program.type == 4 ?

                                <div style={{
                                  height: "180px",
                                  overflowY: "auto",
                                  scrollbarWidth: 'none',
                                }}>

                                  <Typography sx={{ fontSize: "12px !important", }}>

                                    <p>
                                      30-hour Drivers Education class that covers all aspects of Road awareness and Road safety.
                                    </p>


                                  </Typography>


                                  <Typography
                                    onClick={handlePackageNavigate}
                                    sx={{
                                      marginTop: '10px',
                                      fontSize: '15px !important',
                                      cursor: 'pointer',
                                      color: '#ffffff !important',
                                      textDecoration: 'none',
                                      background: 'linear-gradient(145deg, #1976d2, #1565c0)',
                                      padding: '12px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)', // Gentle shadow
                                      transition: 'transform 0.2s ease, box-shadow 0.3s ease',
                                      '&:hover': {
                                        transform: 'scale(1.03)',
                                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)', // Slightly deeper shadow on hover
                                      },
                                      '&:active': {
                                        transform: 'scale(0.98)',
                                      },
                                    }}
                                  >
                                    Buy Drivers Education at {' '}
                                    <b style={{ fontSize: '21px', textDecoration: 'underline' }}>$95</b>{' '}
                                    With Driving Lessons
                                  </Typography>


                                </div>

                                :

                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px !important",
                                      mt: 1,
                                      height: "200px",
                                      overflowY: "auto",
                                      scrollbarWidth: 'none',


                                    }}

                                  >
                                    <p>
                                      {program?.description}
                                    </p>
                                  </Typography>
                                </>
                          }




                        </Grid>

                        <Grid
                          className="prgrm_but"

                        >
                          <Button
                            // className="btns btn_1 hvrtemp-bouncetemp-totemp-righttemp"
                            // onClick={() => setRegisterStatus(true)}
                            sx={{
                              margin: '0 0 10px 10px',
                              padding: '10px 20px',
                              '&:hover': {
                                backgroundColor: 'black',
                                color: 'white'
                              }
                            }}
                            variant="contained"
                            onClick={() => {
                              switch (program.productid) {
                                case 1:
                                  navigate("/schedule-driving-lessons-2", {
                                    state: { number: 2 }
                                  })
                                  break;
                                case 2:
                                  navigate("/dui-risk-reduction-course-remote")
                                  break;
                                case 3:
                                  navigate("/defensive-driving-course-remote")
                                  break;
                                case 4:
                                  navigate("/drivers-education")
                                  break;
                                case 5:
                                  navigate("/road-test-services")
                                  break;
                                default:
                                  navigate("/schedule-driving-lessons-2", {
                                    state: { number: 2 }
                                  })
                                  break;
                              }
                            }}
                          >
                            {buttonTextHelper(program.productid)}
                          </Button>
                          <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            data={program.productid}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Slider>

          </div>
        </Container>
      </Box >
    </>
  );
};

export default HomeProgram;
